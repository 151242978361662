




























import { ContentItemSummaryResult, ContentItemType, ContentsClient, DrugContentItemSummaryResult } from "@/api/DoceoApi";
import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import Vue from "vue";
import { EventTimeCategory, EventTimeName, useAnalyticsStore } from "@/store/analyticsStore";
import DrugContentItemGroupSummaryResult from "@/models/drugContentItemGroupSummaryResult";

export default Vue.extend({
  components: { ContentItemSummary },
  metaInfo() {
    return {
      link: [
        {
          rel: "canonical",
          href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}`,
        },
      ],
    };
  },

  watch: {
    $route: async function() {
      this.page = 0;
      this.contentItems = [];
      this.contentType = null;
      window.onscroll = this.checkScroll;
      this.isLoading = true;
      await this.loadItems();
      this.isLoading = false;
    },
  },

  data() {
    return {
      contentItems: [] as ContentItemSummaryResult[],
      searchTerm: "",
      contentType: null as ContentItemType | null,
      isLoading: false,
      page: 0,
      loadingNextPage: false,
      stopLoading: false,
      itemsPerPage: 30,
    };
  },

  async mounted() {
    this.isLoading = true;
    let itemsLoaded = await this.loadItems();
    this.isLoading = false;

    // load until there are no more items or the screen is filled or the user navigated away
    while (itemsLoaded == this.itemsPerPage && this.page < 50 && !this.stopLoading) {
      itemsLoaded = await this.checkScroll();
    }

    // set up scroll handler
    window.onscroll = this.checkScroll;
  },

  beforeDestroy() {
    // remove scroll handler
    window.onscroll = async () => {};
  },

  methods: {
    /**
     * Loads search results
     * Returns how many results were loaded
     */
    async loadItems(): Promise<number> {
      const contentsClient = new ContentsClient();
      this.searchTerm = this.$route.query.searchTerm as string;
      let offset = this.page * this.itemsPerPage;
      let typeKeyword = "type:";
      let isRecent = null as boolean | null; // This is only used for trials to determine if we should get recent/upcoming trials otherwise null

      if (this.$route.query.contentType) {
        this.contentType = this.$route.query.contentType as ContentItemType;
      } else {
        this.contentType = null;
      }

      if (this.searchTerm.includes(typeKeyword)) {
        const split = this.searchTerm.split(typeKeyword);
        this.searchTerm = split[0].trim();
        let typeEntered = (split.pop() ?? "").trim();

        switch (typeEntered?.toLowerCase()) {
          case "journal":
          case "article":
            this.contentType = ContentItemType.Article;
            break;
          case "drug":
          case "drugGroup":
            this.contentType = ContentItemType.DrugBrandGroup;
            break;
          case "trial":
          case "clinicaltrial":
            this.contentType = ContentItemType.Trial;
            break;
          case "recenttrial":
          case "recentclinicaltrial":
            this.contentType = ContentItemType.Trial;
            isRecent = true;
            break;
          case "upcomingtrial":
          case "upcomingclinicaltrial":
            this.contentType = ContentItemType.Trial;
            isRecent = false;
            break;
          case "decision":
            this.contentType = ContentItemType.Decision;
        }
      }

      const analyticsStore = useAnalyticsStore();
      let startTime = new Date();
      let newContentItems = await contentsClient.getContentItems(this.searchTerm, this.itemsPerPage, offset, this.contentType, isRecent);
      analyticsStore.submitEventTimer(startTime, new Date(), EventTimeName.loadSearchItems, EventTimeCategory.search);
      let fetchCount = newContentItems.length;

      this.contentItems = this.contentItems.concat(newContentItems);
      return fetchCount;
    },

    /**
     * Checks the scroll position and loads more items if close to bottom of the page.
     * Returns how many results were loaded (can be used to load content in a loop until the whole page is filled)
     */
    async checkScroll(): Promise<number> {
      let loadedNewContent = 0;

      if (!this.loadingNextPage) {
        let currentPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight;
        let documentHeight = document.documentElement.offsetHeight;
        let offset = 1000; // How many pixels before the end of the page, we want to fetch the next set of summaries

        if (currentPosition >= documentHeight - offset) {
          this.loadingNextPage = true;
          this.page++;
          loadedNewContent = await this.loadItems();
          this.loadingNextPage = false;

          if (loadedNewContent < this.itemsPerPage) {
            window.onscroll = null;
          }
        }
      }

      return loadedNewContent;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.stopLoading = true;
    next();
  },
});
